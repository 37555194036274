import React from "react";
import { useEffect, useState } from "react";
import MiniSpark from "../MiniSpark";

const TabContent = (props) => {
  const [tabIndex, setTabIndex] = useState(["", "Overview", "0", ""]);
  const teams = [
    { id: 0, color: "#FFFFFF", text: "Inactive" },
    { id: 1, color: "#2FC09A", text: "Operations" },
    { id: 2, color: "#0D6277", text: "Relationship Managers" },
    { id: 3, color: "#FF8667", text: "Creators and Consultants" },
    { id: 4, color: "#FCA2CC", text: "Marketing" },
    { id: 5, color: "#FFFFFF", text: "All" },
  ];
  const content = [
    {
      titleID: "0",
      title: "Overview",
      action: "Send draft to client",
      cycleNumber: " ",
      content: [
        {
          id: "",
          team: 2,
          content: (
            <div className="RM">
              <p>
                <i>Flow everywhere</i> is one of our values; it relates to flow
                theory, but also to the simple notion that it should be easy to
                do business with us, and easy for us to collaborate and work
                well with one another.
              </p>
              <p>
                All our work is informed by{" "}
                <b>strategic, conceptual and creative direction</b> (represented
                by the squiggle that runs through everything).
              </p>
              <p>
                At the heart of our approach are{" "}
                <b>key management processes and systems</b>, consistently
                applied to make us extremely efficient.
              </p>
              <p>
                The <b>lifecycle of a project</b> is represented by the project
                processes outlined in the six phases. These include project
                management and scheduling, as well as the finance cycle.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "0",
      title: "Filing Conventions",
      action: "Send draft to client",
      cycleNumber: " ",
      content: [
        {
          id: "",
          team: 2,
          content: (
            <div className="RM">
              <h3>File names</h3>
              <div>DateBackwards-JobNumber-Descriptor-Version</div>
              <ul>
                <li>
                  <i className="ml-0">
                  E.g., 20230908-4613-Script-V1
                  </i>
                </li>
                <li>
                  <i className="ml-0">E.g., 20231031-4606-BrandPresentation-V2</i>
                </li>
              </ul>
              <h3>Folders</h3>
              <div>
                <h4 className="display">KOT</h4>
                <p>
                  ‘Keep on Top’
                  <br />
                  Includes procurement documents, contracts, timelines, any
                  project management information and costing.<br/>
                  Preface the <i>KOT</i> folder name with a space so that this
                  subfolder stays at the top of the folder.
                </p>
                <h4 className="display">Archive</h4>
                <p>
                  Archive folders can live in any part of the project and should
                  include past versions to avoid any confusion.<br/>
                  Preface the <i>Archive</i> folder name with a space so that this
                  subfolder stays at the top of the folder.
                </p>
                <h4 className="display">Current projects</h4>
                <p>ProjectNumberS or L RMInitials | Client | ProjectName<br/>
                *S stands for Sydney, and L stands for London.</p>
                <h4 className="display">Templated project folders</h4>
                <p>
                  Templated project folders can be found in the <i>FLOW</i> folder at
                  the top of <i>02 CURRENT PROJECTS</i>, or by using this{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.dropbox.com/home/%2002%20CURRENT%20PROJECTS/%20FLOW/%20FOLDER%20STRUCTURE%20TEMPLATE"
                  >
                    link
                  </a>
                  .
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "beingBrave",
      title: "Opportunities",
      action: "BEING Brave",
      cycleNumber: 1,
      content: [
        {
          id: "marketing-1_1-dot",
          team: 4,
          content: (
            <div>
              <h3>Being Brave</h3>
              <div>
                <b>Chambers and networking groups</b>
                <ul className="list-square">
                  <li>
                    Continually monitor chambers and networking groups for
                    events, networking opportunities, directories, and members
                    associated with groups in both Sydney and London. This
                    includes:
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.britishchamber.com/"
                        >
                          Australian British Chamber of Commerce
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.australiachamber.co.uk/"
                        >
                          Australia-UK Chamber of Commerce
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://businesschicks.com/"
                        >
                          Business Chicks
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://au.egn.com/"
                        >
                          EGN
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.ewomennetwork.com/"
                        >
                          eWomenNetwork
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.londonchamber.co.uk/"
                        >
                          London Chamber of Commerce and Industry
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.swisscham.com.au/"
                        >
                          SwissCham Australia
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://fisherpeopleinculture.com/wforw/"
                        >
                          WforW
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <b>LinkedIn</b>
                <ul className="list-square">
                  <li>
                    Connect with system-generated Sales Nav leads every month.
                  </li>
                  <li>
                    Use 250 monthly credits to invite connections to like The
                    Being Group page. (Monitor credits, as they refill
                    throughout the month when people accept/decline.)
                  </li>
                  <li>Engage with content and people of interest.</li>
                  <li>
                    Message new connections using copy in the LinkedIn tab of
                    the master marketing document.
                  </li>
                </ul>
                <b>Social media</b>
                <ul className="list-square">
                  <li>
                    Social media content research: competitor research, trends,
                    relevant Instagram posts, LinkedIn posts to reshare, client
                    and supplier activity, relevant Instagram Reel ideas,
                    showcase ideas.
                  </li>
                  <li>
                    Research initiatives and/or charities BEING can partner
                    with, working within the spaces we’re passionate about, and
                    how we can showcase this on socials.
                  </li>
                  <li>
                    All social media content creation requires the input of a
                    Copywriter, Designer and/or Digital Marketer.
                  </li>
                </ul>
                <b>Events</b>
                <ul className="list-square">
                  <li>Research for events to be hosted at BEING HQ.</li>
                  <li>Research expos we can sponsor, exhibit at, or attend.</li>
                  <li>Research and attend networking events.</li>
                </ul>
                <b>Grants</b>
                <ul className="list-square">
                  <li>
                    Grant research: festivals and events in London, Sydney,
                    Canberra and Melbourne.
                  </li>
                </ul>
                <b>Being Brave: giant postcards</b>
                <ul className="list-square">
                  <li>
                    Being Brave giant postcards based on Sales Nav lead
                    list/dream client list, focused on senior role functions.
                  </li>
                  <li>
                    Writing in city-specific giant postcard master document
                    template, to be personalised by Strategic Content Writer or
                    a Copywriter.
                  </li>
                  <li>Confirm correct address and recipient before posting.</li>
                  <li>
                    Deliver postcard and update shared document of giant
                    postcard delivery details.
                  </li>
                  <li>
                    Follow up with recipient up after seven days, then follow up
                    again after two weeks.
                  </li>
                </ul>
                <b>Email marketing</b>
                <ul className="list-square">
                  <li>
                    Monthly entire database marketing EDM. This interchanges
                    between a culture piece, or the folio-focused BEING
                    Features.
                  </li>
                  <li>
                    Press release-type email marketing content: timely emails
                    sent out based on our work/initiatives or a certain date or
                    activity.
                  </li>
                  <li>
                    All email marketing requires the input of a Copywriter,
                    Designer and Digital Marketer.
                  </li>
                </ul>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "quoting",
      title: "Opportunities",
      action: "Quoting",
      cycleNumber: 1,
      content: [
        {
          id: "rm-1_1-dot",
          team: 2,
          content: (
            <div>
              <h3>Quoting</h3>
              <div>
                <ul className="list-square">
                  <li>
                    We receive opportunities through approaching businesses,
                    tender RFQs, or new clients approaching us. This must be
                    added to the Streamtime board <i>Pipeline</i> into the{" "}
                    <i>In Process</i> column as soon as received, and assigned
                    to a Relationship Manager for completion.
                  </li>
                  <li>
                    Opportunities sometimes also come to us through word of
                    mouth, scope increase to current projects, or because good
                    work we’ve done in the past has led to more.
                  </li>
                  <li>
                    The assigned Relationship Manager is to create a Dropbox
                    folder in <i>01 PIPELINE {">"} 2 IN PROCESS</i> that
                    includes the client’s name and project, and save all
                    available resources, notes, and working files.
                    <br />
                    If it is a formal tender RFQ, we must also create
                    sub-folders:
                    <ul>
                      <li>
                        <b>RFQ</b>: With all clean client-supplied documents.
                      </li>
                      <li>
                        <b>Working Files</b>: E.g., BEING proposal or a copy of
                        a client-supplied template for filling out; and
                      </li>
                      <li>
                        <b>Submission</b>: Only for final documents.
                      </li>
                    </ul>
                  </li>
                  <li>
                    In addition to their work with existing clients, 
                    Relationship Managers are responsible for systematically looking for:
                    <ul>
                      <li>
                        Potential tender opportunities
                      </li>
                      <li>
                        Additional opportunities with current clients 
                      </li>
                      <li>
                        New opportunities with former clients
                      </li>
                      <li>
                        Prospecting for new business 
                      </li>
                      <li>
                        Responding to requests for proposals, quotations etc. 
                      </li>
                    </ul>
                    Any other person can also look for opportunities with their existing networks, 
                    or other opportunities in their areas of expertise or generally.
                  </li>
                  <li>
                    Once an opportunity is identified, 
                    an RM must engage with a Subject Specialist to develop a plan of action, 
                    including an approach to winning the work. Subject Specialists are: 
                    <ul>
                      <li>
                        CEO
                      </li>
                      <li>
                        Principal
                      </li>
                      <li>
                        Visual Media Director (Head of London in the UK)
                      </li>
                      <li>
                        Creative Director
                      </li>
                      <li>
                        Head of Web
                      </li>
                      <li>
                        Copy Lead
                      </li>
                      <li>
                        Digital Lead
                      </li>
                      <li>
                        Principal Consultants
                      </li>
                      <li>
                        Senior Consultants.
                      </li>
                    </ul>
                    If any of these Specialists are on leave, 
                    ask the CEO or Principal who else might temporarily fill that role. 
                    Under the direction of the Specialist, 
                    another suitable member of a team might support a proposal, 
                    such a Senior Designer or Producer.
                  </li>
                  <li>
                    A proposal or tender response must be developed collaboratively by the RM and the Subject Specialist. 
                    One or the other could take a leading role, 
                    but the Subject Specialist must always lead the technical response. 
                    The RM might actually draft the response, but it must be informed by the Subject Specialist. 
                    Sometimes the Subject Specialist might do it all. 
                    It is also possible that more than one Subject Specialist could be involved in a complex proposal, 
                    e.g. Principal Consultant and Creative Director.
                  </li>
                  <li>
                    For big projects the Operations Manager should be consulted about timings.
                  </li>
                  <li>
                    Either the CEO or Principal must be consulted about any complex requirements, 
                    and one of them must sign off on ALL proposals. 
                    The person whose signature appears on the proposal must actually see the proposal before it is sent off. 
                    In their absence they could delegate this to one of the Directors.
                  </li>
                  <li>
                    This process applies equally to Sydney and London. 
                    London has its own Subject Specialists (Head of London, Copy Lead and Senior Consultant). 
                    Until London has its own Creative Director, 
                    Head of Web and Digital Lead, 
                    the Sydney people in these three roles will work with the RMs in London.
                  </li>
                  <li>
                    Until London has its own Relationship Management Lead, 
                    the Sydney RM Lead will provide support to the London RMs as needed. 
                    They do, however, report to the Head of London.
                  </li>
                  <li>
                    Until London has its own Operations Manager, 
                    the Sydney Operations Manager will provide support to London as needed.
                  </li>
                  <li>
                    The RM Lead in Sydney is responsible for maintaining RM standards and managing RM workflow in Sydney, 
                    and also supporting the Head of London and London RMs until a London RM Lead is appointed.
                  </li>
                  <li>
                    If an opportunity is assessed and we find it’s not suitable
                    for us — e.g., we don’t have the right expertise or
                    capabilities, or their budget is too small to be feasible —
                    move the folder to <i>01 PIPELINE {">"} 5 NO BID</i>.
                  </li>
                  <li>
                    If an opportunity is assessed and we find it is suitable for
                    us, send the proposal to the client as soon as possible and
                    move its card to the <i>AWAITING OUTCOME</i> column in the
                    Pipeline board in Streamtime, and the folder of the same
                    name in Dropbox. Follow up when appropriate, noting the date
                    you followed up with client in the Pipeline board in
                    Streamtime.
                  </li>
                  <li>
                    If a job doesn’t convert, move the card to the{" "}
                    <i>UNSUCCESSFUL</i> column in Pipeline in Streamtime, and
                    the same folder in Dropbox.
                  </li>
                </ul>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "openJob",
      title: "Conversion",
      action: "Open Job",
      cycleNumber: 2,
      content: [
        {
          id: "operations-2_1-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Open job</h3>
              <div>
                Based on email notice and/or verbal briefing from Relationship
                Manager:
                <ul className="list-square">
                  <li>
                    Move the Dropbox pipeline folder to{" "}
                    <i>02 CURRENT PROJECTS</i> and assign a job number.
                    <ul>
                      <li>Create one folder (KOT) for original contents.</li>
                      <li>
                        Add <i>FLOW</i> folders for departments that are
                        involved in the project.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Create Microsoft Teams group chat ([Job Number] – Client –
                    Job).
                  </li>
                  <li>Open the job in Streamtime.</li>
                  <li>Add all tasks and any available milestones.</li>
                  <li>Allocate personnel.</li>
                  <li>
                    Prompt the Relationship Manager to add any new documentation
                    that has come through to the <i>Resources</i> Dropbox
                    folder, and for them to double-check the job set-up where
                    appropriate.
                  </li>
                </ul>
              </div>
            </div>
          ),
        },
        {
          id: "marketing-2_1-dot",
          team: 4,
          content: (
            <div className="M">
              <h3>Open job</h3>
              <div>
                <ul className="list-square">
                  <li>
                    With details supplied by the Relationship Manager, add
                    client contact details to both database spreadsheets on
                    Dropbox and the HubSpot marketing database.
                  </li>
                  <li>Set as a marketing contact and add to email list.</li>
                </ul>
              </div>
            </div>
          ),
        },
        {
          id: "rm-2_1-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Open job</h3>
              <div>
                Ring the lunar animal bell when it converts!
                <ul className="list-square">
                  <li>
                    Email Operations with the subject line{" "}
                    <i>ATTN OPERATIONS NEW JOB TO OPEN</i> marked as urgent,
                    including details:
                    <ul>
                      <li>Who the A is and who should be working on it.</li>
                      <li>Timeline.</li>
                      <li>
                        Any pricing variations specific to this project/client.
                      </li>
                      <li>Dropbox link.</li>
                    </ul>
                  </li>
                  <li>
                    Communicate the job verbally to Operations where possible.
                    Do not ‘set and forget’ after this email.
                  </li>
                  <li>
                    Save any documents or resources (brand guidelines, logo
                    files, research, reports, videos etc.) as they become
                    available to the Dropbox folder <i>Resources</i>.
                  </li>
                  <li>
                    For jobs converted through the tendering process, you may be
                    sent a contract for signature. Please review the contract
                    before sending to Kerry or Siebert to sign. Save to{" "}
                    <i>KOT</i> folder.
                  </li>
                  <li>
                    Send the client the kick-off invoice. This is typically for
                    the project’s first 50%, but some projects may have unique
                    payment milestones.
                  </li>
                  <li>
                    Immediately add the project’s payment milestones to the
                    <i>Invoice Tracking</i> board on Streamtime.
                  </li>
                  <li>
                    Once the invoice is sent, save it to the job’s Dropbox
                    folder under <i>KOT</i>. (It’s important to also save the
                    Xero quote/invoice or proposal in the <i>KOT</i> folder too,
                    to act as a source of truth for scope.)
                  </li>
                  <li>
                    Send marketing client contact for database to marketing
                    team.
                  </li>
                  <li>
                    <b>Post a draft brief</b> in Streamtime, including the
                    points below. This should be updated following the kick-off
                    meeting and/or availability of more information,
                    developments or further input from the Leads/Directors.
                    <ul>
                      <li>A:</li>
                      <li>
                        Background Information and/or Project/Client Context:
                        (including relevant links)
                      </li>
                      <li>Timings:</li>
                      <li>
                        Mandatories: (logos, colours, brand guidelines,
                        accessibility requirements etc.)
                      </li>
                      <li>Messaging, Target Audience and Objective:</li>
                      <li>Exact Deliverables and Specifications:</li>
                      <li>
                        Strategic, Conceptual and Creative Direction: (creative
                        briefing input from RFQ, client, Creative
                        Director/Visual Media Director/Lead)
                      </li>
                      <li>Relevant Resources and/or Examples:</li>
                    </ul>
                  </li>
                  <li>
                    Tag the relevant Lead/Director, in case they pick up any
                    issues or gaps ahead of the client kick-off meeting, or can
                    offer further written direction.
                  </li>
                </ul>
              </div>
            </div>
          ),
        },
        {
          id: "",
          team: 4,
          content: (
            <div className="M">
              <ul className="list-square">
                <li>
                  With details supplied by the Relationship Manager, add client
                  contact details to both database spreadsheets on Dropbox and
                  the HubSpot marketing database.
                </li>
                <li>Set as a marketing contact and add to email list.</li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "clientKickoffMeeting",
      title: "Commencement",
      action: "Client kick-off meeting",
      cycleNumber: 3,
      content: [
        {
          id: "creators-3_1-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Client kick off meeting (where applicable)</h3>
              <ul className="list-square">
                <li>
                  <b>Before the meeting</b>: Read the brief post in Streamtime
                  (Job {">"} Activity).
                </li>
                <li>
                  <b>During the meeting</b>: Take your own notes to aid you in
                  completing your work.
                </li>
                <li>
                  <b>Be proactive</b>: Ask the client clarifying questions in
                  this meeting.
                </li>
              </ul>
              <h3>Internal kick-off meeting </h3>
              <ul className="list-square">
                <li>
                  <b>Before the meeting</b>: Re-read the brief post in
                  Streamtime.
                </li>
                <li>
                  <b>During the meeting</b>: Take your own notes to aid you in
                  completing your work.
                </li>
                <li>
                  <b>Be proactive</b>: Ask team members clarifying questions in
                  this meeting.
                </li>
              </ul>
              <br />
              There may be exceptions for some projects where a client or more
              formal internal kick-off meeting is not possible due to timeline
              or other reasons. In this case, seek any further briefing
              information you require from the Relationship Manager.
            </div>
          ),
        },
        {
          id: "rm-3_1-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Client kick-off meeting</h3>
              <div>
                <ul className="list-square">
                  <li>
                    Schedule a kick-off meeting with the client and relevant
                    BEING project team members. It is your responsibility to
                    facilitate the meeting and ensure the following items are
                    clarified:
                    <ul>
                      <li>Timelines.</li>
                      <li>Exact scope is agreed upon.</li>
                      <li>Additional briefing information.</li>
                      <li>Timeline.</li>
                      <li>Next steps/timeline.</li>
                    </ul>
                  </li>
                  <li>
                    Once the client kick-off is completed, use this time (while
                    the entire project team is together) to raise any internal
                    concerns and identify anything that is unclear/missing. This
                    internal kick-off can be scheduled when appropriate, if not
                    at this time.
                  </li>
                </ul>
                <br />
                There may be exceptions for some projects where a client or more
                formal internal kick-off meeting is not possible due to timeline
                or other reasons. In this case, seek any further briefing
                information you require from the Relationship Manager.
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "updateBriefTimelineAndSchedules",
      title: "Commencement",
      action: "Update brief, timeline and schedules",
      cycleNumber: 3,
      content: [
        {
          id: "rm-3_2-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Update brief</h3>
              <ul className="list-square">
                <li>
                  Update the Streamtime brief and ensure all relevant team
                  members and Operations are tagged, communicating if changes
                  need to be made to timelines or creators’ schedules.
                </li>
                <li>
                  Save any documents or resources to Dropbox as they become
                  available.
                </li>
                <li>
                  Assist with ensuring the relevant Lead/Director reviews
                  internal drafts on their Streamtime review board, before
                  sending any drafts to clients.
                </li>
                <li>
                  You are responsible, along with Operations, to ensure tasks
                  are completed on time, to budget, and within time frames.
                  Check in on creators to make sure things are going to plan and
                  if they need further clarification from client.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "operations-3_1-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Update timeline and schedule</h3>
              <ul className="list-square">
                <li>
                  Update the timeline and schedules based on updates from the
                  Relationship Manager following client kick-off meetings.
                </li>
                <li>
                  Ensure the Relationship Manager debriefs you on updates
                  required, as well as updates the brief and tags all relevant
                  team members.
                </li>
                <li>
                  You are jointly responsible for supporting and ensuring that
                  tasks are completed on time, to budget, and within timeframes.
                  Check in on creators to make sure things are going to plan and
                  ask if they need further clarification from the client.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      // rm-3_3-dot"
      titleID: "internalKickOffMeeting",
      title: "Commencement",
      action: "Internal kick-off meeting",
      cycleNumber: 3,
      content: [
        {
          id: "creators-3_2-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Internal draft and review</h3>
              <ul className="list-square">
                <li>
                  Before the meeting: Re-read the brief post in Streamtime.
                </li>
                <li>
                  During the meeting: Take your own notes to aid you in
                  completing your work.
                </li>
                <li>
                  Be proactive: Ask team members clarifying questions in this
                  meeting.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      // rm-3_3-dot"
      titleID: "internalDrafting",
      title: "Commencement",
      action: "Internal drafting",
      cycleNumber: 3,
      content: [
        {
          id: "rm-3_3-dot",
          team: 2,
          content: (
            <div className="O">
              <h3>Internal draft and review</h3>
              <ul className="list-square">
                <li>
                  Assist with checking in on team members’ progress and
                  adherence to promised deadlines.
                </li>
                <li>
                  Assist with ensuring the relevant Lead/Director reviews
                  internal drafts on their Streamtime review board, before
                  sending to client.
                </li>
                <li>
                  Ensure Operations has scheduled time for creators to implement
                  internal feedback.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "operations-3_2-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Internal draft and review</h3>
              <ul className="list-square">
                <li>
                  Assist with checking in on team members’ progress and
                  adherence to promised deadlines.
                </li>
                <li>
                  Assist with ensuring the relevant Leads/Directors review
                  internal drafts on their Streamtime review boards before
                  sending to client.
                </li>
                <li>
                  Ensure Operations has scheduled time for creators to implement
                  internal feedback.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "creators-3_3-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Internal drafting</h3>
              <ul className="list-square">
                <li>
                  Re-read the updated brief. If no brief is available, do not
                  commence. Flag with Operations or the Relationship Manager.
                </li>
                <li>
                  Ensure you’ve received adequate creative direction from the
                  relevant Lead/Director before commencing work.
                </li>
                <li>
                  Once work is scheduled for you on Streamtime, ensure you
                  understand the hours allocated and deadline for each task, as
                  well as scope. (E.g., page numbers or video length.)
                </li>
                <li>
                  You are responsible for ensuring your task is completed on
                  time, within budget, and within allocated timeframes.
                </li>
                <li>
                  <b>See a problem with meeting deadlines</b> or{" "}
                  <b>staying in scope? </b>
                  Communicate this to Operations or the Relationship Manager if
                  you foresee problems.
                </li>
              </ul>

              <h3>Internal review</h3>
              <ul className="list-square">
                <li>
                  <b>Add it</b> to your Lead/Director’s <i>Internal Review</i>{" "}
                  board in Streamtime and/or schedule a face-to-face review time
                  via your Outlook calendar.
                </li>
                <li>
                  <b>Be proactive</b>. The task is still your responsibility.
                  Factor in the time you’ll need for actioning the internal
                  feedback.
                </li>
                <li>
                  <b>Communicate and follow up</b> with your Lead/Director or
                  Operations where needed — especially if you know the deadline
                  is urgent.
                </li>
                <li>
                  <b>Action the feedback</b> from your Lead/Director. Time for
                  this should be allocated by Operations in your schedule.
                </li>
                <li>
                  <b>Submit your work</b>. Provide a link to the client-ready
                  draft of your task and tag the Relationship Manager in
                  Streamtime. Include any creative notes, or questions you’d
                  like them to ask the client.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "feedbackReceived",
      title: "Client Review",
      action: "Feedback received",
      cycleNumber: 4,
      content: [
        {
          id: "rm-4_1-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Feedback received</h3>
              <ul className="list-square">
                <li>
                  Load feedback into Streamtime by translating client
                  communications into clear, easy-to-action lists — linking to
                  any relevant references.
                </li>
                <li>
                  If feedback is received verbally/virtually, email the client
                  for written confirmation/sign-off before actioning.
                </li>
                <li>
                  Assess whether clarification or a verbal debrief with the
                  creator is necessary.
                </li>
                <li>
                  Ensure this is added to schedules by Operations, by tagging
                  them, verbally prompting, or communicating in the project
                  Microsoft Teams chat.
                </li>
                <li>Save any feedback, documents or resources to Dropbox.</li>
                <li>
                  Feedback should be saved in the relevant department folder
                  (e.g., Copy {">"} Website Copy) in <i>2 – Feedback</i>. If no
                  feedback folder exists, create one.
                </li>
                <li>
                  Once feedback and resources are saved to the Dropbox, link
                  them in a new Streamtime post and tag the creator, Operations
                  and other relevant team members.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "operations-4_1-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Feedback received</h3>
              <ul className="list-square">
                <li>
                  Add any feedback to creators’ schedules once the Relationship
                  Manager has loaded it to Streamtime.
                </li>
                <li>
                  Check if feedback is complete and makes sense before
                  scheduling work.
                </li>
                <li>
                  Ensure the Relationship Manager debriefs you on updates to
                  schedules required, posts feedback in Streamtime, and tags all
                  relevant team members.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "tidyFiling",
      title: "Client Review",
      action: "Tidy filing",
      cycleNumber: 4,
      content: [
        {
          id: "creators-4_1-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Tidy filing</h3>
              <ul className="list-square">
                <li>
                  Ensure your drafts and working files are saved neatly,
                  accurately, and named correctly, in a way that ensures greater
                  version control. V1, V2, V3 for clients, V1a, V1b, V1c for
                  internal review.
                </li>
                <li>
                  Archive any old versions of files in the <i>1 – Archive</i>{" "}
                  folder, so anyone can find the latest version of a file with
                  ease.
                </li>
                <li>
                  <b>Designers</b>: Package your files.
                </li>
                <li>
                  <b>Editors</b>: Save exported drafts in the Dropbox project
                  folder, not on the hard drive.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "sendDraftToClient",
      title: "Client Review",
      action: "Send draft to client",
      cycleNumber: 4,
      content: [
        {
          id: "rm-4_2-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Send the draft to client</h3>
              <ul className="list-square">
                <li>
                  Communicate any notes from the creator of the draft
                  deliverable to the client. (They should have written any notes
                  in Streamtime when they tagged you.)
                </li>
                <li>
                  Clearly outline next steps to the client and ensure this is
                  also reflected in Streamtime.
                </li>
                <li>
                  Clearly note which draft the version is (e.g., version 2) and
                  how many revisions remain in scope.
                </li>
                <li>
                  Ensure Operations and relevant project members are cc’d into
                  emails when content goes to the client.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "actionVersionFeedback",
      title: "Refinement",
      action: "Action version feedback",
      cycleNumber: 5,
      content: [
        {
          id: "operations-5_1-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Action version feedback</h3>
              <ul className="list-square">
                <li>
                  Assist with checking in on team members’ progress and
                  adherence to promised deadlines for returning the next version
                  of deliverables. Communicate any issues or delays to the
                  Relationship Manager.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until complete
                  or out of scope.
                </li>
                <li>
                  Flag with Relationship Managers immediately when out of scope
                  requests are received and/or hours have blown out.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "rm-5_1-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Action version feedback</h3>
              <ul className="list-square">
                <li>
                  Check in on team members’ progress and adherence to promised
                  deadlines where appropriate.
                </li>
                <li>
                  Assist with ensuring the relevant Lead/Director reviews
                  internal drafts on their Streamtime review board, before
                  sending drafts to clients.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until out of
                  scope/complete.
                </li>
                <li>
                  Flag to client immediately when out of scope requests are
                  received and/or hours have blown out.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "creators-5_1-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Action version feedback</h3>
              <ul className="list-square">
                <li>
                  There should be time scheduled for this in your To Do. (Speak
                  to Operations if needed.)
                </li>
                <li>
                  <b>Before commencing the next version of your work:</b>
                  <ul>
                    <li>
                      Review feedback posted in Streamtime by the Relationship
                      Manager.
                    </li>
                    <li>
                      Seek verbal clarification or guidance from your team
                      Lead/Director whenever required.
                    </li>
                  </ul>
                </li>
                <li>
                  If you are working in a Word document, track any changes made
                  from V2 on. (This is important for version control and the
                  client’s review and approval.) Save a tracked and a clean
                  version when complete.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until complete
                  or out of scope.
                </li>
              </ul>
              <h3>Process repeats through the phases:</h3>
              <div>
                Internal drafting > Internal review > Draft to client >
                Refinement until the project is complete or out of scope (speak
                to Operations or Relationship Manager)
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "actionFinalFeedback",
      title: "Refinement",
      action: "Action final feedback",
      cycleNumber: 5,
      content: [
        {
          id: "operations-5_2-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Action final feedback</h3>
              <ul className="list-square">
                <li>
                  Assist with checking in on team members’ progress and
                  adherence to promised deadlines for returning the next version
                  of deliverables. Communicate any issues or delays to the
                  Relationship Manager.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until complete
                  or out of scope.
                </li>
                <li>
                  Flag with Relationship Managers immediately when out of scope
                  requests are received and/or hours have blown out.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "rm-5_2-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Action final feedback</h3>
              <ul className="list-square">
                <li>
                  Check in on team members’ progress and adherence to promised
                  deadlines where appropriate.
                </li>
                <li>
                  Assist with ensuring the relevant Lead/Director reviews
                  internal drafts on their Streamtime review board, before
                  sending drafts to clients.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until out of
                  scope/complete.
                </li>
                <li>
                  Flag to client immediately when out of scope requests are
                  received and/or hours have blown out.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "creators-5_2-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Action final feedback</h3>
              <ul className="list-square">
                <li>
                  There should be time scheduled for this in your To Do. (Speak
                  to Operations if needed.)
                </li>
                <li>
                  <b>Before commencing the next version of your work:</b>
                  <ul>
                    <li>
                      Review feedback posted in Streamtime by the Relationship
                      Manager.
                    </li>
                    <li>
                      Seek verbal clarification or guidance from your team
                      Lead/Director whenever required.
                    </li>
                  </ul>
                </li>
                <li>
                  If you are working in a Word document, track any changes made
                  from V2 on. (This is important for version control and the
                  client’s review and approval.) Save a tracked and a clean
                  version when complete.
                </li>
                <li>
                  Repeat actions in internal drafting, internal review, draft to
                  client, feedback received and refinement phases until complete
                  or out of scope.
                </li>
              </ul>
              <h3>Process repeats through the phases:</h3>
              <div>
                Internal drafting > Internal review > Draft to client >
                Refinement until the project is complete or out of scope (speak
                to Operations or Relationship Manager)
              </div>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "obtainClientSign-off",
      title: "Refinement",
      action: "obtainClientSign-off",
      cycleNumber: 5,
      content: [
        {
          id: "rm-5_3-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Obtain client sign-off</h3>
              <ul className="list-square">
                <li>
                  Once the final version is reached and/or the deliverable is
                  satisfactory to the client, obtain written client sign-off.
                </li>
                <li>
                  Prepare and send all in-scope final/print-ready files to the
                  client with the help of the project team.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "beingGrateful",
      title: "Conclusion",
      action: "BEING Grateful",
      cycleNumber: 6,
      content: [
        {
          id: "operations-6_1-dot",
          team: 1,
          content: (
            <div className="RM">
              <h3>BEING Grateful</h3>
              <ul className="list-square">
                <li>
                  Let the marketing team know to send a ‘Being Grateful’ card.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "rm-6_1-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Being Grateful</h3>
              <ul className="list-square">
                <li>
                  Request a testimonial from the client. Once received, share
                  the testimonial with the Copy Lead or a Copywriter to be saved
                  to Dropbox.
                </li>
                <li>
                  Set up a calendar reminder for yourself to follow up with the
                  client following release of content to ask how it was
                  received, if relevant.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "marketing-6_1-dot",
          team: 4,
          content: (
            <div className="M">
              <h3>Being Grateful</h3>
              <ul className="list-square">
                <li>
                  Remind the Relationship Manager to request a testimonial from
                  the client.
                </li>
                <li>
                  Work with the Relationship Manager to identify best time to
                  send a ‘Being Grateful’ card, bookmark and ‘Share the Love’
                  card, prompting the client to leave a positive Google review.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    {
      titleID: "closeJob",
      title: "Conclusion",
      action: "Close job",
      cycleNumber: 6,
      content: [
        {
          id: "rm-6_2-dot",
          team: 2,
          content: (
            <div className="RM">
              <h3>Close job </h3>
              <ul className="list-square">
                <li>
                  Send the final invoice to the client, save to the <i>KOT</i>{" "}
                  folder, and remove from the <i>Invoice Tracking</i> board on
                  Streamtime.
                </li>
                <li>
                  Communicate to Operations that job is ready to be closed.
                </li>
                <li>
                  Debrief with the BEING project team on what went well — or
                  what didn’t — and identity opportunities to improve our work
                  on similar projects next time.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "creators-6_1-dot",
          team: 3,
          content: (
            <div className="C">
              <h3>Close job</h3>
              Tidy filing
              <ul className="list-square">
                <li>
                  Ensure your drafts and working files are saved neatly,
                  accurately, and named correctly.
                </li>
                <li>
                  <b>Designers</b>: Package all art files.
                </li>
                <li>
                  <b>Editors</b>: Save the final version. Ensure it’s clearly
                  named and filed in the Dropbox project folder, along with any
                  other assets you used (like stock footage, music, SFX, LUTs,
                  and graphics) not on the hard drive.
                </li>
                <li>
                  <b>All:</b> Archive any old versions in the <i>Archive</i>{" "}
                  folder.
                </li>
                <li>
                  <b>All</b>: Debrief with the BEING project team on what went
                  well — or what didn’t — and identity opportunities to improve
                  our work on similar projects next time.
                </li>
              </ul>
              Create folios
              <ul className="list-square">
                <li>
                  You may be allocated work to support with creating a folio,
                  case study or social media assets for some projects. This is
                  scheduled through Streamtime as an internal job.
                </li>
                <li>
                  If more creative briefing is required, speak to the creators
                  who worked on the original project for more information.
                  (E.g., Copywriter, Designer, Web Developer etc.)
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "operations-6_2-dot",
          team: 1,
          content: (
            <div className="O">
              <h3>Close job</h3>
              <ul className="list-square">
                <li>Mark job as complete in Streamtime.</li>
                <li>
                  Move project folder to <i>03 CLIENTS</i> folder (create a new
                  folder for the client if none exists).
                </li>
                <li>
                  Schedule folio, case study and social media asset creation.
                </li>
                <li>
                  Debrief with the BEING project team on what went well — or
                  what didn’t — and identity opportunities to improve our work
                  on similar projects next time.
                </li>
              </ul>
            </div>
          ),
        },
        {
          id: "marketing-6_2-dot",
          team: 4,
          content: (
            <div className="M">
              <h3>Folio and capabilities document</h3>
              <ul className="list-square">
                <li>
                  Upload folios to the BEING website to showcase new work.
                </li>
                <li>
                  If a project is ongoing client work, supply digital marketing
                  statistics for a Designer to create updated assets for the
                  capabilities document.
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    setTabIndex(props.targetTab);
  }, [props]);

  return (
    <>
      {content.map((item, i) => {
        if (item.title === tabIndex[1] && item.titleID === tabIndex[2]) {
          return (
            <div key={i + item.title}>
              <div
                className={`${
                  i === 0 ? "pt-10" : "pt-7"
                } border-t border-b border-black pb-3.5 flex items-end justify-between`}
              >
                <div className="text-[48px] font-medium">
                  <div className="">
                    {item.content.map((contents, i) => {
                      if (
                        tabIndex[0] === "tspan" &&
                        tabIndex[3] === item.titleID
                      ) {
                        return (
                          <div className="flex">
                            <MiniSpark team={contents.team} />
                            <p className="pl-5 text-lg font-normal">
                              {teams[contents.team].text}
                            </p>
                          </div>
                        );
                      } else if (tabIndex[2] === "0") {
                        return null;
                      } else if (contents.id === tabIndex[3]) {
                        return (
                          <div className="flex">
                            <MiniSpark team={contents.team} />
                            <p className="pl-5 text-lg font-normal">
                              {teams[contents.team].text}
                            </p>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <h2>{item.title}</h2>
                </div>

                {item.titleID && (
                  <h2 className="text-[84px] line-height-1 leading-none">
                    {item.cycleNumber}
                  </h2>
                )}
              </div>
              <div className="mt-10">
                <div className="text-md">
                  {item.content.map((contents, i) => {
                    if (
                      tabIndex[0] === "tspan" &&
                      tabIndex[3] === item.titleID
                    ) {
                      return contents.content;
                    } else if (contents.id === tabIndex[3]) {
                      return contents.content;
                    }
                  })}
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

export default TabContent;
