import { React } from "react";

const ColorHighlight = (props) => {
  const colors = [
    { id: 1, color: "bg-[#2FC09A]", text: "Operations" },
    { id: 2, color: "bg-[#0D6277]", text: "Relationship Managers" },
    { id: 3, color: "bg-[#FF8667]", text: "Creators and Consultants" },
    { id: 4, color: "bg-[#FCA2CC]", text: "Marketing" },
  ];

  const clickHandler = (event) => {
    const value = event.currentTarget.dataset.value;
    props.getTeamVal(value);
  };
  function clickComponentHandler (e){
    props.clickComponent(e)
  }
  return (
    <div onClick={clickComponentHandler}>
      <div className="border-2 max-w-full w-[690px] border-black py-3 px-4 ml-16">
        <div className="flex items-center gap-x-3.5 mb-3 min-w-[630px]">
        <button id="teams"
        data-value={6}
                onClick={clickHandler}
                className="teams-showall flex items-center gap-x-1.5">
                  <p className="">
                    <span id="teams" data-value={6} className="text-lg font-medium rm-padding-bottom deck">Teams</span>
                    <span id="teams" data-value={6} className="text-lg font-medium rm-padding-bottom display"> (show all)</span>
                  </p>
          
          
          
        </button>
        
          {colors.map((item, i) => {
            return (
              <button
                key={i}
                id={"buttonTeam" + i}
                className="flex items-center gap-x-1"
                onClick={clickHandler}
                data-value={i + 1}
              >
                <div
                  id="teams"
                  className={`w-4 h-4 ${item.color} rounded-full`}
                />
                <p id="teams" data-value={i + 1} className="text-sm">
                  {item.text}
                </p>
              </button>
            );
          })}
        </div>
        <div>
        <a onClick={clickHandler} id="fillingConventions" className="font-medium">
          Filing Conventions
        </a>
      </div>
      </div>
      
    </div>
  );
};

export default ColorHighlight;
