import React from "react";
import Landing from "./Landing";
import { MetaTags } from "react-meta-tags";
import(/* webpackPrefetch: true */ "./index.css");


function App() {
  return (
    <div className="container">
      <MetaTags>
        <meta name="robots" content="noindex"></meta>
        <meta name="googlebot" content="noindex"></meta>
        <link rel="stylesheet" href="https://use.typekit.net/rch2jbx.css"></link>
      </MetaTags>

      <Landing />
    </div>
  );
}

export default App;
