import { useState, useEffect, React } from "react";

const Asset = (props) => {
  const teams = [
    {
      id: 0,
      color: "#D0D1D1",
      text: "Inactive",
      isTeamActive: true,
      bold: 2,
      dot: 0,
    },
    {
      id: 1,
      color: "#2FC09A",
      text: "Operations",
      isTeamActive: true,
      bold: 4,
      dot: 0,
    },
    {
      id: 2,
      color: "#0D6277",
      text: "Relationship Managers",
      isTeamActive: true,
      bold: 4,
      dot: 0,
    },
    {
      id: 3,
      color: "#FF8667",
      text: "Creators",
      isTeamActive: true,
      bold: 4,
      dot: 0,
    },
    {
      id: 4,
      color: "#FCA2CC",
      text: "Marketing",
      isTeamActive: true,
      bold: 4,
      dot: 0,
    },
    {
      id: 5,
      color: "#FFFFFF",
      text: "All",
      isTeamActive: true,
      bold: 2,
      dot: 0,
    },
    {
      id: 6,
      color: "#FFFFFF",
      text: "Small",
      isTeamActive: true,
      bold: 4,
      dot: 0,
    },
    {
      id: 7,
      color: "#FFFFFF",
      text: "Large",
      isTeamActive: true,
      bold: 2,
      dot: 0,
    },
    {
      id: 8,
      color: "#FFFFFF",
      text: "Medium",
      isTeamActive: true,
      bold: 2,
      dot: 0,
    },
  ];
  const [teamClicked, setTeamClicked] = useState(teams[props.highlightTeam]);

  const [isActive, setIsActive] = useState(false);

  function handleClick(e) {
    let tagName = e.target.tagName;
    let cycleId,
      actionId,
      targetID = "";
    if (tagName === "tspan") {
      targetID = e.target.parentElement.id;
      cycleId = e.target.parentElement.parentElement.parentElement.id;
      actionId = e.target.parentElement.parentElement.id;
    } else if (tagName === "path") {
      targetID = e.target.id;
      cycleId = e.target.parentElement.parentElement.parentElement.id;
      actionId = e.target.parentElement.parentElement.id;
    }
    if (e.type === "click") {
      props.getClickVal(tagName, cycleId, actionId, targetID);
      setIsActive(targetID);
    } else if (e.type === "mouseleave" || e.type === "mouseenter") {
      if (!props.isClicked) {
        if (e.type === "mouseenter") {
          setIsActive(targetID);
        }
        props.clickHandler(e);
      }
    }
    return isActive;
  }

  function handleMouseLeave(e) {
    if (!props.isClicked) {
      if (e.type === "mouseleave") {
        setIsActive(false);
      }
      props.clickHandler(e);
    }
  }

  useEffect(() => {
    setTeamClicked(teams[props.highlightTeam]);
    if (isActive && teamClicked.id === 5) {
      setIsActive(false);
    } else {
    }
  }, [props.highlightTeam]);

  function handleComponentClick(e) {
    let tagName = e.target.tagName;
    let targetID = e.target.id;
    props.clickHandler(e);
    if (tagName === "path") {
      setIsActive(targetID);
    } else {
      setIsActive("false");
    }
  }

  function fillColor(teamNumber, dot, type) {
    if (type === "dot") {
      if (isActive) {
        if (
          dot.split("-")[0] === isActive.split("-")[0] ||
          !props.isClicked ||
          isActive === dot ||
          teamClicked.id === 6
        ) {
          return teams[teamNumber].color;
        } else {  
          return teams[0].color;
        }
      }
    }
    if (
      teamClicked.id === teamNumber ||
      !props.isClicked ||
      isActive === dot ||
      teamClicked.id === 6
    ) {
      return teams[teamNumber].color;
    } else {
      return teams[0].color;
    }
  }

  function fillStroke(teamNumber, dot) {
    if (isActive === dot) {
      return teams[teamNumber].color;
    } else {
      return "";
    }
  }

  function fillStrokeWidth(teamNumber, dot) {
    if (isActive === dot) {
      return teams[teamNumber].bold;
    } else {
      return teams[0].bold;
    }
  }

  function fillStrokeWidthDot(teamNumber, dot, size) {
    if (isActive === dot) {
      return teams[0].dot;
    } else {
      if (size === "small") {
        return teams[6].bold;
      } else if (size === "large") {
        return teams[7].bold;
      } else if (size === "medium") {
        return teams[8].bold;
      }
    }
  }

  function fillFontWidth(dot) {
    if (isActive === dot) {
      return 600;
    } else {
      return 400;
    }
  }

  function clickComponentHandler(e) {
    props.clickHandler(e);
  }

  return (
    <div>
      <svg
        onClick={handleComponentClick}
        className="max-w-full"
        width="1019"
        height="748"
        viewBox="0 0 1019 750"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="squiggle"
          d="M1016.83 436.274C980.555 451.404 938.907 448.041 904.115 430.398C869.323 412.756 839.483 380.864 835.81 342.106C834.216 325.297 839.123 305.761 855.865 298.28C871.679 291.217 890.557 299.148 900.703 312.174C912.951 327.893 911.214 349.941 902.592 366.918C892.339 387.109 872.865 399.678 851.815 406.401C829.294 413.591 804.881 415.466 781.364 415.297C757.847 415.128 733.833 412.294 710.413 408.123C661.414 399.398 613.72 384.84 565.18 374.036C517.371 363.394 467.661 356.178 418.881 364.348C396.051 368.171 373.914 375.659 351.858 382.526C328.094 389.923 304.343 397.417 280.275 403.768C237.813 414.972 194.422 420.196 150.483 418.539C108.216 416.944 68.9577 404.252 33.4305 381.229C22.378 374.064 11.83 366.165 1.60528 357.863C0.672383 357.105 2.00879 355.795 2.93427 356.545C37.6374 384.723 76.7488 407.078 121.372 413.973C140.12 416.871 159.145 417.262 178.074 416.653C198.713 415.986 219.371 414.742 239.717 411.063C285.077 402.858 328.802 387.422 372.828 374.118C393.192 367.964 413.507 362.525 434.73 360.359C457.055 358.084 479.595 358.742 501.874 361.192C593.351 371.254 679.862 410.019 772.336 413.235C795.469 414.039 819.181 412.801 841.733 407.284C864.352 401.75 886.476 390.611 898.782 369.967C909.787 351.506 912.122 325.845 895.934 309.59C881.521 295.117 856.537 292.263 844.049 310.753C833.143 326.901 836.83 349.825 844.065 366.669C851.591 384.189 864.379 399.631 878.954 411.72C909.023 436.66 950.088 448.818 988.767 442.448C998.083 440.913 1007.17 438.29 1015.89 434.655C1016.99 434.197 1017.93 435.816 1016.83 436.278L1016.83 436.274Z"
          fill="#D0D1D1"
        />
        <g>
          <g id="Opportunities">
            <g id="beingBrave">
              <g id="dot">
                <path
                  id="marketing-1_1-dot"
                  d="M585.065 90.3379C590.038 90.3379 594.069 86.3084 594.069 81.3379C594.069 76.3673 590.038 72.3379 585.065 72.3379C580.092 72.3379 576.061 76.3673 576.061 81.3379C576.061 86.3084 580.092 90.3379 585.065 90.3379Z"
                  fill={fillColor(4, "marketing-1_1-dot", "dot")}
                  stroke={fillStroke(4, "marketing-1_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    4,
                    "marketing-1_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="marketing-1_1"
                d="M501.716 66.9422C506.294 66.9422 509.87 69.9042 510.83 74.4901C514.652 92.7417 519.842 110.615 526.351 128.009"
                stroke={fillColor(4, "marketing-1_1-dot", "line")}
                strokeWidth={fillStrokeWidth(4, "marketing-1_1-dot")}
                strokeMiterlimit="10"
              />
              <text
                id="beingBrave"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={fillFontWidth("marketing-1_1-dot")}
                letterSpacing="0em"
              >
                <tspan x="602.812" y="85.8343">
                  Being Brave
                </tspan>
              </text>
            </g>
            <g id="quoting">
              <g id="dot">
                <path
                  id="rm-1_1-dot"
                  d="M585.065 113.219C590.038 113.219 594.069 109.19 594.069 104.219C594.069 99.2489 590.038 95.2195 585.065 95.2195C580.092 95.2195 576.061 99.2489 576.061 104.219C576.061 109.19 580.092 113.219 585.065 113.219Z"
                  fill={fillColor(2, "rm-1_1-dot", "dot")}
                  stroke={fillStroke(2, "rm-1_1-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-1_1-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>

              <path
                id="rm-1_1"
                d="M526.351 128.02C540.492 165.81 560.825 201.302 586.717 233.332"
                stroke={fillColor(2, "rm-1_1-dot", "line")}
                strokeWidth={fillStrokeWidth(2, "rm-1_1-dot")}
                strokeMiterlimit="10"
              />
              <path
                id="rm-1_1-arrow"
                d="M547.216 173.735V164.85L539.522 169.293L547.216 173.735Z"
                fill={fillColor(2, "rm-1_1-dot", "arrow")}
                stroke={fillStroke(2, "rm-1_1-dot")}
              />
              <text
                id="quoting"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={fillFontWidth("rm-1_1-dot")}
                letterSpacing="0em"
              >
                <tspan x="602.812" y="108.716">
                  Quoting
                </tspan>
              </text>
            </g>

            <g id="Text box">
              <text
                id="1"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="572.599" y="54.2">
                  1
                </tspan>
              </text>
              <text
                id="Opportunities"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="607.224" y="52.5805">
                  Opportunities
                </tspan>
              </text>
            </g>
          </g>
          <g id="Conversion">
            <g id="openJob">
              <g id="dot">
                <path
                  id="operations-2_1-dot"
                  d="M680.683 259.689C685.655 259.689 689.686 255.66 689.686 250.689C689.686 245.718 685.655 241.689 680.683 241.689C675.71 241.689 671.679 245.718 671.679 250.689C671.679 255.66 675.71 259.689 680.683 259.689Z"
                  fill={fillColor(1, "operations-2_1-dot", "dot")}
                  stroke={fillStroke(1, "operations-2_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-2_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>

              <path
                id="operations-2_1-arrow"
                d="M691.052 316.174L687.159 308.187L682.183 315.551L691.052 316.174Z"
                fill={fillColor(1, "operations-2_1-dot", "arrow")}
                strokeWidth={fillStroke(1, "operations-2_1-dot")}
              />
              <path
                id="operations-2_1"
                d="M593.44 230.268C602.8 241.737 612.885 252.748 623.675 263.247C672.649 310.934 735.478 347.08 800.596 365.025C806.768 366.721 810.824 372.144 810.701 378.528"
                stroke={fillColor(1, "operations-2_1-dot", "line")}
                strokeWidth={fillStrokeWidth(1, "operations-2_1-dot")}
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="rm-2_1-dot"
                  d="M659.185 259.689C664.158 259.689 668.189 255.66 668.189 250.689C668.189 245.718 664.158 241.689 659.185 241.689C654.212 241.689 650.181 245.718 650.181 250.689C650.181 255.66 654.212 259.689 659.185 259.689Z"
                  fill={fillColor(2, "rm-2_1-dot", "dot")}
                  stroke={fillStroke(2, "rm-2_1-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-2_1-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="marketing-2_1-dot"
                  d="M699.696 258.393C703.838 258.393 707.196 255.035 707.196 250.893C707.196 246.75 703.838 243.393 699.696 243.393C695.554 243.393 692.196 246.75 692.196 250.893C692.196 255.035 695.554 258.393 699.696 258.393Z"
                  fill={fillColor(4, "marketing-2_1-dot", "dot")}
                  stroke={fillStroke(4, "marketing-2_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    2,
                    "marketing-2_1-dot",
                    "medium"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>

              <path
                id="rm-2_1-arrow"
                d="M643.703 290.64L640.74 282.265L634.956 289.016L643.703 290.64Z"
                fill={fillColor(2, "rm-2_1-dot", "arrow")}
                strokeWidth={fillStroke(2, "rm-2_1-dot")}
              />

              <path
                id="rm-2_1"
                d="M586.727 233.332C596.587 245.537 607.254 257.231 618.688 268.364C668.51 316.879 732.443 353.658 798.705 371.919C801.719 372.747 803.62 375.28 803.559 378.385"
                stroke={fillColor(2, "rm-2_1-dot", "line")}
                strokeWidth={fillStrokeWidth(2, "rm-2_1-dot")}
                strokeMiterlimit="10"
              />

              <text
                id="openJob"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "rm-2_1-dot" && fillFontWidth("rm-2_1-dot")) ||
                  (isActive === "operations-2_1-dot" &&
                    fillFontWidth("operations-2_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="716.783" y="255.889">
                  Open job
                </tspan>
              </text>
            </g>

            <g id="Text box">
              <text
                id="conversion"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="705.467" y="222.278">
                  Conversion
                </tspan>
              </text>
              <text
                id="2"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="653.927" y="223.21">
                  2
                </tspan>
              </text>
            </g>
          </g>
          <g id="Commencement">
            <g id="clientKickoffMeeting">
              <g id="dot">
                <path
                  id="rm-3_1-dot"
                  d="M676.899 598.123C681.884 598.123 685.926 594.094 685.926 589.123C685.926 584.152 681.884 580.123 676.899 580.123C671.913 580.123 667.871 584.152 667.871 589.123C667.871 594.094 671.913 598.123 676.899 598.123Z"
                  fill={fillColor(2, "rm-3_1-dot")}
                  stroke={fillStroke(2, "rm-3_1-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-3_1-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="rm-3_1"
                d="M803.55 378.395C803.489 381.428 801.527 383.839 798.553 384.533C748.404 396.238 701.474 419.515 660.082 452.934"
                stroke={
                  (isActive === "creators-3_1-dot" &&
                    fillColor(2, "creators-3_1-dot")) ||
                  (isActive === "rm-3_1-dot" && fillColor(2, "rm-3_1-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 2 ||
                  teamClicked.id === 6
                    ? fillColor(2, "rm-3_1-dot")
                    : teams[0].color)
                }
                strokeWidth={
                  (isActive === "creators-3_1-dot" &&
                    fillStrokeWidth(2, "creators-3_1-dot")) ||
                  (isActive === "rm-3_1-dot" &&
                    fillStrokeWidth(2, "rm-3_1-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 2 ||
                  teamClicked.id === 6
                    ? fillStrokeWidth(2, "rm-3_1-dot")
                    : teams[0].color)
                }
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="creators-3_1-dot"
                  d="M655.843 598.123C660.829 598.123 664.871 594.094 664.871 589.123C664.871 584.152 660.829 580.123 655.843 580.123C650.858 580.123 646.816 584.152 646.816 589.123C646.816 594.094 650.858 598.123 655.843 598.123Z"
                  fill={fillColor(3, "creators-3_1-dot")}
                  stroke={fillStroke(3, "creators-3_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-3_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>

              <text
                id="clientKickoffMeeting"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "rm-3_1-dot" && fillFontWidth("rm-3_1-dot")) ||
                  (isActive === "creators-3_1-dot" &&
                    fillFontWidth("creators-3_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="709.067" y="595.964">
                  Client and internal  kick-off meetings
                </tspan>
              </text>
            </g>
            <g id="internalKickOffMeeting">
              <g id="dot">
                {/* <path
                  id="creators-3_2-dot"
                  d="M655.388 621.468C660.374 621.468 664.416 617.439 664.416 612.468C664.416 607.497 660.374 603.468 655.388 603.468C650.402 603.468 646.361 607.497 646.361 612.468C646.361 617.439 650.402 621.468 655.388 621.468Z"
                  fill={fillColor(3, "creators-3_2-dot", "dot")}
                  stroke={fillStroke(3, "creators-3_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-3_2-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                /> */}
              </g>
              <path
                id="creators-3_1"
                d="M810.702 378.528C810.579 384.84 806.349 390.049 800.178 391.489C769.749 398.587 740.516 410.057 713.03 425.541"
                stroke={fillColor(3, "creators-3_2-dot", "line")}
                strokeWidth={fillStrokeWidth(3, "creators-3_2-dot")}
                strokeMiterlimit="10"
              />
              {/* <text
                id="Reflect in schedules_10"
                fill="black"
                font-family="forma-djr-deck"
                font-size="14"
                letter-spacing="0em"
                fontWeight={
                  isActive === "creators-3_2-dot" &&
                  fillFontWidth("creators-3_2-dot")
                }
              >
                <tspan x="709.159" y="616.668">
                  Internal kick-off meeting
                </tspan>
              </text> */}
            </g>
            <g id="updateBriefTimelineAndSchedules">
              <g id="dot">
                <path
                  id="operations-3_1-dot"
                  d="M655.843 620.371C660.829 620.371 664.871 616.342 664.871 611.371C664.871 606.401 660.829 602.371 655.843 602.371C650.858 602.371 646.816 606.401 646.816 611.371C646.816 616.342 650.858 620.371 655.843 620.371Z"
                  fill={fillColor(1, "operations-3_1-dot")}
                  stroke={fillStroke(1, "operations-3_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-3_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="operations-3_1"
                d="M660.082 452.923C641.261 468.111 623.584 485.402 607.266 504.645"
                stroke={
                  (isActive === "operations-3_1-dot" &&
                    fillColor(1, "operations-3_1-dot")) ||
                  (isActive === "rm-3_2-dot" && fillColor(1, "rm-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 1 ||
                  teamClicked.id === 6
                    ? fillColor(1, "operations-3_1-dot")
                    : teams[0].color)
                }
                strokeWidth={
                  (isActive === "operations-3_1-dot" &&
                    fillStrokeWidth(1, "operations-3_1-dot")) ||
                  (isActive === "rm-3_2-dot" &&
                    fillStrokeWidth(1, "rm-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 1 ||
                  teamClicked.id === 6
                    ? fillStrokeWidth(1, "operations-3_1-dot")
                    : teams[0].color)
                }
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="rm-3_2-dot"
                  d="M675.371 618.871C679.513 618.871 682.871 615.513 682.871 611.371C682.871 607.229 679.513 603.871 675.371 603.871C671.229 603.871 667.871 607.229 667.871 611.371C667.871 615.513 671.229 618.871 675.371 618.871Z"
                  fill={fillColor(2, "rm-3_2-dot")}
                  stroke={fillStroke(2, "rm-3_2-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-3_2-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <text
                id="updateBriefTimelineAndSchedules"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "rm-3_2-dot" && fillFontWidth("rm-3_2-dot")) ||
                  (isActive === "operations-3_1-dot" &&
                    fillFontWidth("operations-3_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="709.039" y="615.368">
                  Update brief, timeline and schedules
                </tspan>
              </text>
            </g>
            <g id="internalDrafting">
              <g id="dot">
                <path
                  id="creators-3_3-dot"
                  d="M655.843 642.371C660.829 642.371 664.871 638.342 664.871 633.371C664.871 628.401 660.829 624.371 655.843 624.371C650.858 624.371 646.816 628.401 646.816 633.371C646.816 638.342 650.858 642.371 655.843 642.371Z"
                  fill={fillColor(3, "creators-3_3-dot", "dot")}
                  stroke={fillStroke(3, "creators-3_3-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-3_3-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="rm-3_3-dot"
                  d="M675.394 640.871C679.549 640.871 682.917 637.513 682.917 633.371C682.917 629.229 679.549 625.871 675.394 625.871C671.239 625.871 667.871 629.229 667.871 633.371C667.871 637.513 671.239 640.871 675.394 640.871Z"
                  fill={fillColor(2, "rm-3_3-dot", "dot")}
                  stroke={fillStroke(2, "rm-3_3-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-3_3-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="operations-3_2-dot"
                  d="M693.44 640.871C697.595 640.871 700.963 637.513 700.963 633.371C700.963 629.229 697.595 625.871 693.44 625.871C689.286 625.871 685.917 629.229 685.917 633.371C685.917 637.513 689.286 640.871 693.44 640.871Z"
                  fill={fillColor(1, "operations-3_2-dot", "dot")}
                  stroke={fillStroke(1, "operations-3_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-3_2-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="creators-3_2-arrow"
                d="M575.417 540.747L581.62 544.325L575.417 547.902V540.747Z"
                fill={
                  (isActive === "creators-3_3-dot" &&
                    fillColor(3, "creators-3_3-dot", "arrow")) ||
                  (isActive === "rm-3_3-dot" &&
                    fillColor(3, "rm-3_3-dot", "arrow")) ||
                  (isActive === "operations-3_2-dot" &&
                    fillColor(3, "operations-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillColor(3, "creators-3_3-dot", "arrow")
                    : teams[0].color)
                }
                stroke={
                  (isActive === "creators-3_3-dot" &&
                    fillStroke(3, "creators-3_3-dot")) ||
                  (isActive === "rm-3_3-dot" && fillStroke(3, "rm-3_3-dot")) ||
                  (isActive === "operations-3_2-dot" &&
                    fillStroke(3, "operations-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillStroke(3, "creators-3_3-dot")
                    : teams[0].color)
                }
              />

              <path
                id="creators-3_2"
                d="M607.275 504.645C555.022 566.284 520.7 643.355 508.03 727.515C507.519 730.896 504.965 733.081 501.511 733.081"
                stroke={
                  (isActive === "creators-3_3-dot" &&
                    fillColor(3, "creators-3_3-dot")) ||
                  (isActive === "rm-3_3-dot" && fillColor(3, "rm-3_3-dot")) ||
                  (isActive === "operations-3_2-dot" &&
                    fillColor(3, "operations-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillColor(3, "creators-3_3-dot")
                    : teams[0].color)
                }
                strokeWidth={
                  (isActive === "creators-3_3-dot" &&
                    fillStrokeWidth(3, "creators-3_3-dot")) ||
                  (isActive === "rm-3_3-dot" &&
                    fillStrokeWidth(3, "rm-3_3-dot")) ||
                  (isActive === "operations-3_2-dot" &&
                    fillStrokeWidth(3, "operations-3_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillStrokeWidth(1, "creators-3_3-dot")
                    : teams[0].color)
                }
                strokeMiterlimit="10"
              />
              <text
                id="internalDrafting"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "rm-3_3-dot" && fillFontWidth("rm-3_3-dot")) ||
                  (isActive === "creators-3_3-dot" &&
                    fillFontWidth("creators-3_3-dot")) ||
                  (isActive === "operations-3_2-dot" &&
                    fillFontWidth("operations-3_2-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="709.067" y="637.368">
                  Internal drafting and review
                </tspan>
              </text>
            </g>
            <g id="Text box">
              <text
                id="commencement"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="705.467" y="562.079">
                  Commencement
                </tspan>
              </text>
              <text
                id="3"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="652.24" y="562.679">
                  3
                </tspan>
              </text>
            </g>
          </g>
          <g id="Client Review">
            <g id="reflectInSchedules">
              <g id="dot">
                
              </g>
              <path
                id="operations-4_1"
                d="M436.318 561.923C424.322 541.679 404.038 514.301 389.008 496.489"
                stroke={fillColor(1, "operations-4_1-dot", "line")}
                strokeWidth={fillStrokeWidth(1, "operations-4_1-dot")}
                strokeMiterlimit="10"
              />
              
            </g>
            <g id="feedbackReceived">
              <g id="dot">
                <path
                  id="operations-4_1-dot"
                  d="M208.221 694.376C213.194 694.376 217.225 690.346 217.225 685.376C217.225 680.405 213.194 676.376 208.221 676.376C203.249 676.376 199.218 680.405 199.218 685.376C199.218 690.346 203.249 694.376 208.221 694.376Z"
                  fill={fillColor(1, "operations-4_1-dot", "dot")}
                  stroke={fillStroke(1, "operations-4_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-4_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="rm-4_1-dot"
                  d="M187.165 694.376C192.138 694.376 196.169 690.346 196.169 685.376C196.169 680.405 192.138 676.376 187.165 676.376C182.192 676.376 178.161 680.405 178.161 685.376C178.161 690.346 182.192 694.376 187.165 694.376Z"
                  fill={fillColor(2, "rm-4_1-dot", "dot")}
                  stroke={fillStroke(2, "rm-4_1-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-4_1-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="rm-4_1-arrow"
                d="M444.506 576.119L443.781 584.985L451.822 581.185L444.506 576.119Z"
                fill={fillColor(2, "rm-4_1-dot", "arrow")}
                strokeWidth={fillStroke(2, "rm-4_1-dot")}
              />

              <path
                id="rm-4_1"
                d="M472.115 637.871C462.459 611.234 450.484 585.802 436.322 561.923"
                stroke={fillColor(2, "rm-4_1-dot", "line")}
                strokeWidth={fillStrokeWidth(2, "rm-4_1-dot")}
                strokeMiterlimit="10"
              />
              <text
                id="feedbackReceived"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14" 
                fontWeight={
                  (isActive === "rm-4_1-dot" && fillFontWidth("rm-4_1-dot")) ||
                  (isActive === "operations-4_1-dot" &&
                    fillFontWidth("operations-4_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="224.196" y="690.44">
                  Feedback received
                </tspan>
              </text>
            </g>
            <g id="tidyFiling">
              <g id="dot">
                <path
                  id="creators-4_1-dot"
                  d="M187.165 717.944C192.138 717.944 196.169 713.914 196.169 708.944C196.169 703.973 192.138 699.944 187.165 699.944C182.192 699.944 178.161 703.973 178.161 708.944C178.161 713.914 182.192 717.944 187.165 717.944Z"
                  fill={fillColor(3, "creators-4_1-dot", "dot")}
                  stroke={fillStroke(3, "creators-4_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-4_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="creators-4_1"
                d="M486.757 686.283C482.711 669.768 477.817 653.61 472.115 637.881"
                stroke={fillColor(3, "creators-4_1-dot", "line")}
                strokeWidth={fillStrokeWidth(3, "creators-4_1-dot")}
                strokeMiterlimit="10"
              />
              <text
                id="tidyFiling"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={fillFontWidth("creators-4_1-dot")}
                letterSpacing="0em"
              >
                <tspan x="224.196" y="714.144">
                  Tidy filing
                </tspan>
              </text>
            </g>
            <g id="sendDraftToClient">
              <g id="dot">
                <path
                  id="rm-4_2-dot"
                  d="M187.165 672.376C192.138 672.376 196.169 668.346 196.169 663.376C196.169 658.405 192.138 654.376 187.165 654.376C182.192 654.376 178.161 658.405 178.161 663.376C178.161 668.346 182.192 672.376 187.165 672.376Z"
                  fill={fillColor(2, "rm-4_2-dot", "dot")}
                  stroke={fillStroke(2, "rm-4_2-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-4_2-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="rm-4_2"
                d="M501.511 733.082C498.078 733.082 495.523 730.916 495.012 727.556C492.836 713.584 490.087 699.816 486.767 686.283"
                stroke={fillColor(2, "rm-4_2-dot", "line")}
                strokeWidth={fillStrokeWidth(2, "rm-4_2-dot")}
                strokeMiterlimit="10"
              />
              <text
                id="sendDraftToClient"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={fillFontWidth("rm-4_2-dot")}
                letterSpacing="0em"
              >
                <tspan x="224.196" y="668.576">
                  Send draft to client
                </tspan>
              </text>
            </g>
            <g id="Text box">
              <text
                id="4"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="178.467" y="644.591">
                  4
                </tspan>
              </text>
              <text
                id="Client Review"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="228.046" y="641.971">
                  Client Review
                </tspan>
              </text>
            </g>
          </g>
          <g id="Refinement">
            <g id="actionVersionFeedback">
              <g id="dot">
                <path
                  id="creators-5_1-dot"
                  d="M145.397 516.134C150.37 516.134 154.401 512.104 154.401 507.134C154.401 502.163 150.37 498.134 145.397 498.134C140.425 498.134 136.394 502.163 136.394 507.134C136.394 512.104 140.425 516.134 145.397 516.134Z"
                  fill={fillColor(3, "creators-5_1-dot", "dot")}
                  stroke={fillStroke(3, "creators-5_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-5_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="creators-5_1-arrow"
                d="M314.185 427.542L310.319 433.561L307.037 427.204L314.185 427.542Z"
                fill={
                  (isActive === "creators-5_1-dot" &&
                    fillColor(3, "creators-5_1-dot", "arrow")) ||
                  (isActive === "operations-5_1-dot" &&
                    fillColor(3, "operations-5_1-dot", "arrow")) ||
                  (isActive === "rm-5_1-dot" &&
                    fillColor(3, "rm-5_1-dot", "arrow")) ||
                  (isActive === "creators-5_2-dot" &&
                    fillColor(3, "creators-5_2-dot", "arrow")) ||
                  (isActive === "rm-5_2-dot" &&
                    fillColor(3, "rm-5_2-dot", "arrow")) ||
                  (isActive === "operations-5_2-dot" &&
                    fillColor(3, "operations-5_2-dot", "arrow")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillColor(3, "creators-5_1-dot", "arrow")
                    : teams[0].color)
                }
                stroke={
                  (isActive === "creators-5_1-dot" &&
                    fillStroke(3, "creators-5_1-dot")) ||
                  (isActive === "operations-5_1-dot" &&
                    fillStroke(3, "operations-5_1-dot")) ||
                  (isActive === "rm-5_1-dot" && fillStroke(3, "rm-5_1-dot")) ||
                  (isActive === "creators-5_2-dot" &&
                    fillStroke(3, "creators-5_2-dot")) ||
                  (isActive === "rm-5_2-dot" && fillStroke(3, "rm-5_2-dot")) ||
                  (isActive === "operations-5_2-dot" &&
                    fillStroke(3, "operations-5_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillStroke(3, "creators-5_1-dot")
                    : teams[0].color)
                }
              />
              <path
                id="creators-5_1"
                d="M385.872 493.354C344.588 448.412 299.92 416.348 241.678 395.4"
                stroke={
                  (isActive === "creators-5_1-dot" &&
                    fillColor(3, "creators-5_1-dot", "line")) ||
                  (isActive === "operations-5_1-dot" &&
                    fillColor(3, "operations-5_1-dot", "line")) ||
                  (isActive === "rm-5_1-dot" &&
                    fillColor(3, "rm-5_1-dot", "line")) ||
                  (isActive === "creators-5_2-dot" &&
                    fillColor(3, "creators-5_2-dot")) ||
                  (isActive === "rm-5_2-dot" &&
                    fillColor(3, "rm-5_2-dot", "line")) ||
                  (isActive === "operations-5_2-dot" &&
                    fillColor(3, "operations-5_2-dot", "line")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillColor(3, "creators-5_1-dot", "line")
                    : teams[0].color)
                }
                strokeWidth={
                  (isActive === "creators-5_1-dot" &&
                    fillStrokeWidth(3, "creators-5_1-dot")) ||
                  (isActive === "operations-5_1-dot" &&
                    fillStrokeWidth(3, "operations-5_1-dot")) ||
                  (isActive === "rm-5_1-dot" &&
                    fillStrokeWidth(3, "rm-5_1-dot")) ||
                  (isActive === "creators-5_2-dot" &&
                    fillStrokeWidth(3, "creators-5_2-dot")) ||
                  (isActive === "rm-5_2-dot" &&
                    fillStrokeWidth(3, "rm-5_2-dot")) ||
                  (isActive === "operations-5_2-dot" &&
                    fillStrokeWidth(3, "operations-5_2-dot")) ||
                  (!props.isClicked ||
                  teamClicked.id === 3 ||
                  teamClicked.id === 6
                    ? fillStrokeWidth(3, "creators-5_1-dot")
                    : teams[0].color)
                }
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="rm-5_1-dot"
                  d="M164.904 514.634C169.048 514.634 172.407 511.276 172.407 507.134C172.407 502.991 169.048 499.634 164.904 499.634C160.76 499.634 157.401 502.991 157.401 507.134C157.401 511.276 160.76 514.634 164.904 514.634Z"
                  fill={fillColor(2, "rm-5_1-dot", "dot")}
                  stroke={fillStroke(2, "rm-5_1-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-5_1-dot", "medium")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="rm-5_1"
                d="M241.669 395.401C229.694 391.09 217.484 387.475 205.079 384.574C202.086 383.869 200.103 381.449 200.042 378.385"
                stroke={fillColor(2, "rm-5_3-dot", "line")}
                strokeWidth={fillStrokeWidth(2, "rm-5_3-dot")}
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="operations-5_1-dot"
                  d="M182.911 514.634C187.054 514.634 190.414 511.276 190.414 507.134C190.414 502.991 187.054 499.634 182.911 499.634C178.767 499.634 175.407 502.991 175.407 507.134C175.407 511.276 178.767 514.634 182.911 514.634Z"
                  fill={fillColor(1, "operations-5_1-dot", "dot")}
                  stroke={fillStroke(1, "operations-5_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-5_1-dot",
                    "medium"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <text
                id="actionVersionFeedback"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "creators-5_1-dot" &&
                    fillFontWidth("creators-5_1-dot")) ||
                  (isActive === "rm-5_1-dot" && fillFontWidth("rm-5_1-dot")) ||
                  (isActive === "operations-5_1-dot" &&
                    fillFontWidth("operations-5_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="196.001" y="511.63">
                  Action version feedback
                </tspan>
              </text>
            </g>
            <g id="obtainClientSign-off">
              <g id="dot">
                <path
                  id="rm-5_3-dot"
                  d="M145.397 560.133C150.37 560.133 154.401 556.104 154.401 551.133C154.401 546.163 150.37 542.133 145.397 542.133C140.425 542.133 136.394 546.163 136.394 551.133C136.394 556.104 140.425 560.133 145.397 560.133Z"
                  fill={fillColor(2, "rm-5_3-dot", "dot")}
                  stroke={fillStroke(2, "rm-5_3-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-5_3-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <text
                id="obtainClientSign-off"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={fillFontWidth("rm-5_3-dot")}
                letterSpacing="0em"
              >
                <tspan x="194.299" y="555.63">
                  Obtain client sign-off
                </tspan>
              </text>
            </g>
            <g id="actionFinalFeedback">
              <g id="dot">
                <path
                  id="creators-5_2-dot"
                  d="M145.397 538.133C150.37 538.133 154.401 534.104 154.401 529.133C154.401 524.163 150.37 520.133 145.397 520.133C140.425 520.133 136.394 524.163 136.394 529.133C136.394 534.104 140.425 538.133 145.397 538.133Z"
                  fill={fillColor(3, "creators-5_2-dot", "dot")}
                  stroke={fillStroke(3, "creators-5_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-5_2-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="rm-5_2-dot"
                  d="M164.904 536.633C169.048 536.633 172.407 533.275 172.407 529.133C172.407 524.991 169.048 521.633 164.904 521.633C160.76 521.633 157.401 524.991 157.401 529.133C157.401 533.275 160.76 536.633 164.904 536.633Z"
                  fill={fillColor(2, "rm-5_2-dot", "dot")}
                  stroke={fillStroke(2, "rm-5_2-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-5_2-dot", "medium")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="operations-5_2-dot"
                  d="M182.911 536.633C187.054 536.633 190.414 533.275 190.414 529.133C190.414 524.991 187.054 521.633 182.911 521.633C178.767 521.633 175.407 524.991 175.407 529.133C175.407 533.275 178.767 536.633 182.911 536.633Z"
                  fill={fillColor(1, "operations-5_2-dot", "dot")}
                  stroke={fillStroke(1, "operations-5_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-5_2-dot",
                    "medium"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <text
                id="actionFinalFeedback"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "creators-5_2-dot" &&
                    fillFontWidth("creators-5_2-dot")) ||
                  (isActive === "rm-5_2-dot" && fillFontWidth("rm-5_2-dot")) ||
                  (isActive === "operations-5_2-dot" &&
                    fillFontWidth("operations-5_2-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="195.745" y="533.63">
                  Action final feedback
                </tspan>
              </text>
            </g>

            <g id="Text box">
              <text
                id="refinement"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="188.077" y="474.936">
                  Refinement
                </tspan>
              </text>
              <text
                id="5"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="136.577" y="476.39">
                  5
                </tspan>
              </text>
            </g>
          </g>
          <g id="Conclusion">
            <g id="beingGrateful">
              <g id="dot">
                <path
                  id="marketing-6_1-dot"
                  d="M187.672 204.421C192.645 204.421 196.676 200.391 196.676 195.421C196.676 190.45 192.645 186.421 187.672 186.421C182.7 186.421 178.668 190.45 178.668 195.421C178.668 200.391 182.7 204.421 187.672 204.421Z"
                  fill={fillColor(4, "marketing-6_1-dot")}
                  stroke={fillStroke(4, "marketing-6_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    4,
                    "marketing-6_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="marketing-6_1"
                d="M467.374 112.648C471.931 99.2174 475.681 85.5005 478.603 71.5692"
                stroke={
                  (isActive === "marketing-6_1-dot" &&
                    fillColor(4, "marketing-6_1-dot")) ||
                  (isActive === "operations-6_1-dot" &&
                    fillColor(4, "operations-6_1-dot")) ||
                  (isActive === "rm-6_1-dot" && fillColor(4, "rm-6_1-dot")) ||
                  (!props.isClicked || teamClicked.id === 4
                    ? fillColor(4, "marketing-6_1-dot")
                    : teams[0].color)
                }
                strokeWidth={
                  (isActive === "marketing-6_1-dot" &&
                    fillStrokeWidth(4, "marketing-6_1-dot")) ||
                  (isActive === "operations-6_1-dot" &&
                    fillStrokeWidth(4, "operations-6_1-dot")) ||
                  (isActive === "rm-6_1-dot" &&
                    fillStrokeWidth(4, "rm-6_1-dot")) ||
                  (!props.isClicked || teamClicked.id === 4
                    ? fillStrokeWidth(4, "marketing-6_1-dot")
                    : teams[0].color)
                }
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="operations-6_1-dot"
                  d="M207.179 202.921C211.323 202.921 214.682 199.563 214.682 195.421C214.682 191.279 211.323 187.921 207.179 187.921C203.035 187.921 199.676 191.279 199.676 195.421C199.676 199.563 203.035 202.921 207.179 202.921Z"
                  fill={fillColor(1, "operations-6_1-dot")}
                  stroke={fillStroke(1, "operations-6_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-6_1-dot",
                    "medium"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="operations-6_1-arrow"
                d="M296.202 326.183L287.323 325.57L291.226 333.557L296.202 326.183Z"
                fill={fillColor(1, "operations-6_2-dot")}
                strokeWidth={fillStroke(1, "operations-6_2-dot")}
              />
              <path
                id="operations-6_1"
                d="M192.889 378.528C192.766 372.134 196.843 366.68 203.035 364.964C268.112 346.998 330.901 310.842 379.834 263.165C434.049 210.341 470.619 144.596 485.598 73.0295C487.253 65.114 493.732 59.7927 501.712 59.7927"
                stroke={fillColor(1, "operations-6_2-dot")}
                strokeWidth={fillStrokeWidth(1, "operations-6_2-dot")}
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="rm-6_1-dot"
                  d="M225.158 202.921C229.301 202.921 232.661 199.563 232.661 195.421C232.661 191.279 229.301 187.921 225.158 187.921C221.014 187.921 217.655 191.279 217.655 195.421C217.655 199.563 221.014 202.921 225.158 202.921Z"
                  fill={fillColor(2, "rm-6_1-dot")}
                  stroke={fillStroke(2, "rm-6_1-dot")}
                  strokeWidth={fillStrokeWidthDot(1, "rm-6_1-dot", "medium")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="rm-6_1-arrow"
                d="M433.049 212.057L424.957 215.754L432.201 220.912L433.049 212.057Z"
                fill={fillColor(2, "rm-6_2-dot")}
                strokeWidth={fillStroke(2, "rm-6_2-dot")}
              />
              <path
                id="rm-6_1"
                d="M200.042 378.385C199.981 375.26 201.902 372.696 204.947 371.859C271.158 353.576 335.05 316.797 384.821 268.293C440.058 214.478 477.323 147.466 492.588 74.5006C493.549 69.9148 497.125 66.9528 501.702 66.9528"
                stroke={fillColor(2, "rm-6_2-dot")}
                strokeWidth={fillStrokeWidth(2, "rm-6_2-dot")}
                strokeMiterlimit="10"
              />

              <text
                id="beingGrateful"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "marketing-6_1-dot" &&
                    fillFontWidth("marketing-6_1-dot")) ||
                  (isActive === "rm-6_1-dot" && fillFontWidth("rm-6_1-dot")) ||
                  (isActive === "operations-6_1-dot" &&
                    fillFontWidth("operations-6_1-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="263.003" y="199.825">
                  Being Grateful
                </tspan>
              </text>
            </g>
            <g id="closeJob">
              <g id="dot">
                <path
                  id="operations-6_2-dot"
                  d="M187.672 181.171C192.645 181.171 196.676 177.141 196.676 172.171C196.676 167.2 192.645 163.171 187.672 163.171C182.7 163.171 178.668 167.2 178.668 172.171C178.668 177.141 182.7 181.171 187.672 181.171Z"
                  fill={fillColor(1, "operations-6_2-dot", "dot")}
                  stroke={fillStroke(1, "operations-6_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    1,
                    "operations-6_2-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="creators-6_1-dot"
                  d="M208.68 181.171C213.652 181.171 217.683 177.141 217.683 172.171C217.683 167.2 213.652 163.171 208.68 163.171C203.707 163.171 199.676 167.2 199.676 172.171C199.676 177.141 203.707 181.171 208.68 181.171Z"
                  fill={fillColor(3, "creators-6_1-dot", "dot")}
                  stroke={fillStroke(3, "creators-6_1-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    3,
                    "creators-6_1-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <path
                id="creators-6_1-arrow"
                d="M465.678 179.585L459.311 176.312L465.333 172.439L465.678 179.585Z"
                fill={fillColor(3, "creators-6_1-dot", "arrow")}
                strokeWidth={fillStroke(3, "creators-6_1-dot")}
              />
              <path
                id="creators-6_1"
                d="M389.817 273.41C435.613 228.787 469.281 175.247 488.623 116.754"
                stroke={fillColor(3, "creators-6_1-dot", "line")}
                strokeWidth={fillStrokeWidth(3, "creators-6_1-dot")}
                strokeMiterlimit="10"
              />
              <g id="dot">
                <path
                  id="rm-6_2-dot"
                  d="M229.687 181.171C234.66 181.171 238.691 177.141 238.691 172.171C238.691 167.2 234.66 163.171 229.687 163.171C224.714 163.171 220.683 167.2 220.683 172.171C220.683 177.141 224.714 181.171 229.687 181.171Z"
                  fill={fillColor(2, "rm-6_2-dot", "dot")}
                  stroke={fillStroke(2, "rm-6_2-dot")}
                  strokeWidth={fillStrokeWidthDot(2, "rm-6_2-dot", "large")}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
                <path
                  id="marketing-6_2-dot"
                  d="M249.191 179.671C253.333 179.671 256.691 176.313 256.691 172.171C256.691 168.029 253.333 164.671 249.191 164.671C245.049 164.671 241.691 168.029 241.691 172.171C241.691 176.313 245.049 179.671 249.191 179.671Z"
                  fill={fillColor(4, "marketing-6_2-dot", "dot")}
                  stroke={fillStroke(4, "marketing-6_2-dot")}
                  strokeWidth={fillStrokeWidthDot(
                    4,
                    "marketing-6_2-dot",
                    "large"
                  )}
                  onClick={(e) => handleClick(e)}
                  onMouseEnter={(e) => handleClick(e)}
                  onMouseLeave={(e) => handleMouseLeave(e)}
                />
              </g>
              <text
                id="closeJob"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="14"
                fontWeight={
                  (isActive === "creators-6_1-dot" &&
                    fillFontWidth("creators-6_1-dot")) ||
                  (isActive === "rm-6_2-dot" && fillFontWidth("rm-6_2-dot")) ||
                  (isActive === "operations-6_2-dot" &&
                    fillFontWidth("operations-6_2-dot"))
                }
                letterSpacing="0em"
              >
                <tspan x="263.009" y="176.667">
                  Close job
                </tspan>
              </text>
            </g>

            <g id="Text box">
              <text
                id="conclusion"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="22"
                fontWeight="500"
                letterSpacing="0px"
              >
                <tspan x="227.006" y="147.739">
                  Conclusion
                </tspan>
              </text>
              <text
                id="6"
                fill="black"
                fontFamily="forma-djr-deck"
                fontSize="64"
                letterSpacing="0px"
              >
                <tspan x="177.949" y="150.247">
                  6
                </tspan>
              </text>
            </g>
          </g>
          <g id="rectangle">
            <rect
              id="Rectangle-conceptual"
              x="369.01"
              y="351.828"
              width="125.886"
              height="23.6992"
              fill="white"
            />
            <rect
              id="Rectangle-strategic"
              x="391.73"
              y="325.57"
              width="101.755"
              height="26.258"
              fill="white"
            />

            <text
              id="conceptual"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="24.5979"
              fontWeight="500"
              letterSpacing="0em"
            >
              <tspan x="391.73" y="345.781">
                Strategic,{" "}
              </tspan>
              <tspan x="371.239" y="372.781">
                Conceptual
              </tspan>
              <tspan x="359.157" y="399.781">
                and Creative{" "}
              </tspan>
              <tspan x="401.146" y="426.781">
                Direction
              </tspan>
            </text>

            <rect
              id="Rectangle-xero"
              width="28"
              height="16"
              transform="translate(523.924 432.216)"
              fill="white"
            />
            <text
              id="xero"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.888" y="444.712">
                Xero
              </tspan>
            </text>

            <rect
              id="Rectangle-streamTime"
              width="74"
              height="16"
              transform="translate(524.082 369.677)"
              fill="white"
            />
            <text
              id="streamTime"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.987" y="382.173">
                Streamtime
              </tspan>
            </text>

            <rect
              id="Rectangle-msTeams"
              width="64"
              height="16"
              transform="translate(523.924 348.216)"
              fill="white"
            />
            <text
              id="msTeams"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.841" y="360.712">
                Microsoft Teams
              </tspan>
            </text>

            <rect
              id="Rectangle-checkIn"
              width="54"
              height="16"
              transform="translate(523.924 306.216)"
              fill="white"
            />
            <text
              id="checkIn"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.854" y="318.712">
                Check-in
              </tspan>
            </text>

            <rect
              id="Rectangle-outlook"
              width="48"
              height="16"
              transform="translate(523.924 390.216)"
              fill="white"
            />
            <text
              id="outlook"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.862" y="402.712">
                Outlook
              </tspan>
            </text>

            <text
              id="aMeetings"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.924" y="339.712">
                A Meetings
              </tspan>
            </text>

            <rect
              id="Rectangle-dropbox"
              width="54"
              height="16"
              transform="translate(523.924 411.216)"
              fill="white"
            />
            <text
              id="dropbox"
              fill="black"
              fontFamily="forma-djr-deck"
              fontSize="14"
              letterSpacing="0em"
            >
              <tspan x="523.854" y="423.712">
                Dropbox
              </tspan>
            </text>
          </g>
          <g id="vectors">
            <path
              id="Vector_21"
              d="M501.798 76.8598C506.804 76.8598 510.861 72.8037 510.861 67.8003C510.861 62.797 506.804 58.7409 501.798 58.7409C496.793 58.7409 492.735 62.797 492.735 67.8003C492.735 72.8037 496.793 76.8598 501.798 76.8598Z"
              fill="black"
            />

            <path
              id="white-1"
              d="M501.798 81.5039C511.905 81.5039 520.098 73.3141 520.098 63.2114C520.098 53.1088 511.905 44.9189 501.798 44.9189C491.691 44.9189 483.498 53.1088 483.498 63.2114C483.498 73.3141 491.691 81.5039 501.798 81.5039Z"
              fill="white"
            />
            <path
              id="white-4"
              d="M501.798 747.677C511.905 747.677 520.098 739.487 520.098 729.384C520.098 719.282 511.905 711.092 501.798 711.092C491.691 711.092 483.498 719.282 483.498 729.384C483.498 739.487 491.691 747.677 501.798 747.677Z"
              fill="white"
            />
            <path
              id="white-5"
              d="M380.182 506.201C390.289 506.201 398.482 498.011 398.482 487.908C398.482 477.805 390.289 469.616 380.182 469.616C370.075 469.616 361.882 477.805 361.882 487.908C361.882 498.011 370.075 506.201 380.182 506.201Z"
              fill="white"
            />
            <path
              id="white-2"
              d="M588.6 252.1C598.707 252.1 606.9 243.91 606.9 233.808C606.9 223.705 598.707 215.515 588.6 215.515C578.493 215.515 570.3 223.705 570.3 233.808C570.3 243.91 578.493 252.1 588.6 252.1Z"
              fill="white"
            />
            <path
              id="white-3"
              d="M797.929 395.508C808.036 395.508 816.229 387.318 816.229 377.216C816.229 367.113 808.036 358.923 797.929 358.923C787.822 358.923 779.629 367.113 779.629 377.216C779.629 387.318 787.822 395.508 797.929 395.508Z"
              fill="white"
            />
            <path
              id="black-1"
              d="M501.798 74.5336C507.968 74.5336 512.969 69.534 512.969 63.3667C512.969 57.1994 507.968 52.1999 501.798 52.1999C495.628 52.1999 490.626 57.1994 490.626 63.3667C490.626 69.534 495.628 74.5336 501.798 74.5336Z"
              fill="black"
            />
            <path
              id="white-6"
              d="M201.975 395.508C212.082 395.508 220.275 387.318 220.275 377.216C220.275 367.113 212.082 358.923 201.975 358.923C191.868 358.923 183.675 367.113 183.675 377.216C183.675 387.318 191.868 395.508 201.975 395.508Z"
              fill="white"
            />
            <path
              id="black-6"
              d="M202.382 388.382C208.552 388.382 213.554 383.383 213.554 377.216C213.554 371.048 208.552 366.049 202.382 366.049C196.213 366.049 191.211 371.048 191.211 377.216C191.211 383.383 196.213 388.382 202.382 388.382Z"
              fill="black"
            />
            <path
              id="black-3"
              d="M797.929 388.382C804.099 388.382 809.1 383.383 809.1 377.216C809.1 371.048 804.099 366.049 797.929 366.049C791.759 366.049 786.758 371.048 786.758 377.216C786.758 383.383 791.759 388.382 797.929 388.382Z"
              fill="black"
            />
            <path
              id="black-4"
              d="M501.798 740.706C507.968 740.706 512.969 735.707 512.969 729.539C512.969 723.372 507.968 718.373 501.798 718.373C495.628 718.373 490.626 723.372 490.626 729.539C490.626 735.707 495.628 740.706 501.798 740.706Z"
              fill="black"
            />
            <path
              id="black-5"
              d="M380.181 499.075C386.351 499.075 391.353 494.075 391.353 487.908C391.353 481.741 386.351 476.741 380.181 476.741C374.011 476.741 369.01 481.741 369.01 487.908C369.01 494.075 374.011 499.075 380.181 499.075Z"
              fill="black"
            />
            <path
              id="black-2"
              d="M588.6 244.974C594.77 244.974 599.771 239.975 599.771 233.808C599.771 227.64 594.77 222.641 588.6 222.641C582.43 222.641 577.428 227.64 577.428 233.808C577.428 239.975 582.43 244.974 588.6 244.974Z"
              fill="black"
            />
            <path
              id="line-2"
              d="M838.257 233.979L598.257 233.979"
              stroke="black"
              strokeWidth="0.96"
              strokeMiterlimit="10"
            />
            <path
              id="line-6_1"
              d="M375.067 216.226L177.492 216.226"
              stroke="black"
              strokeWidth="0.96"
              strokeMiterlimit="10"
            />
            <path
              id="line-1"
              d="M502.067 62.8662H558.428H742.067"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="line-3_1"
              d="M647.184 506.739L887.184 506.739"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="line-3_2"
              d="M797.929 388.56L797.929 506.464"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="line-6_2"
              d="M202.765 215.829L202.765 372.985"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              id="line-4"
              d="M501.505 730.259H172.275"
              stroke="black"
              strokeWidth="0.96"
              strokeMiterlimit="10"
            />
            <path
              id="line-5"
              d="M374.238 489.312L134.238 489.312"
              stroke="black"
              strokeWidth="0.96"
              strokeMiterlimit="10"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Asset;
