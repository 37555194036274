import React from "react";
import { useRef, useState } from "react";
import { Asset, ColorHighlight, TabContent } from "./components/landing";
const Landing = () => {

  
  const [clicked, setClicked] = useState(false);
  const componentRef = useRef(null);
  const colors = [
    { id: 0, color: "#FFFFFF", text: "Inactive" },
    { id: 1, color: "#2FC09A", text: "Operations" },
    { id: 2, color: "#0D6277", text: "Relationship Managers" },
    { id: 3, color: "#FF8667", text: "Creators" },
    { id: 4, color: "#FCA2CC", text: "Marketing" },
    { id: 5, color: "#FFFFFF", text: "All" },
  ];
  const [onClickVal, setOnClickVal] = useState(["", "Overview", "0", ""]);
  const [highlightTeam, setHighlightTeam] = useState(5);

  const getClickVal = (tagName, cycle, action, value) => {
    setOnClickVal([tagName, cycle, action, value]);
  };

  const getTeamVal = (value) => {
    setHighlightTeam(value);
  };

  const handleComponentClick = (event) => {
    // Clicked inside the component, update state
    if (event.type === "mouseleave") {
      setClicked(false);
      setHighlightTeam(5);
    } else if (event.type === "mouseenter") {
      setClicked(false);
      setHighlightTeam(5);
    } else {
      if (event.target.parentElement.id === "dot") {
        setHighlightTeam(5);
        setClicked(true);
        return;
      } else if (event.target.id === "fillingConventions") {
        setOnClickVal(["", "Filing Conventions", "0", ""]);
        setHighlightTeam(5);
        setClicked(false);
        return;
      }else if (event.target.id === "teams") {
        setClicked(false);
        setClicked(true);
        return;
      } else {
        setClicked(false);
        setOnClickVal(["", "Overview", "0", ""]);
        setHighlightTeam(5);
      }
    }

    return event.target.id
  };
  return (
    <>
      <div className="flex flex-col md:flex-row pt-14 lg:pl-16" ref={componentRef}>
        <div className="pb-20 border-r border-black lg:pr-5 lg:w-5/12 md:w-full sm:w-full md:flex-2 sm:pr-0">
          <p className="">
          <span className="text-[56px] leading-[3rem] font-medium deck">Flow:{" "}</span>
          <span className="text-[56px] leading-[3rem] font-thin display">The BEING approach to projects</span>
          </p>
          <div className="pt-8">
            <TabContent
              targetTab={onClickVal}
              highlightTeam={colors[highlightTeam]}
            />
          </div>
        </div>
        <div className="pb-6 pl-5 lg:w-7/12 spark w-70 md:w-full sm:w-full md:flex-1">
          <div className="relative pb-40">
            <Asset
              clickVal={onClickVal}
              getClickVal={getClickVal}
              clickHandler={handleComponentClick}
              isClicked={clicked}
              highlightTeam={highlightTeam}
            />
            <ColorHighlight
              getTeamVal={getTeamVal}
              clickComponent={handleComponentClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Landing;
