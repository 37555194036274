import React from "react";

const MiniSpark = (props) => {
const colors = [
    { id: 0, color: "#D0D1D1", text: "Inactive" },
    { id: 1, color: "#2FC09A", text: "Operations" },
    { id: 2, color: "#0D6277", text: "Relationship Managers" },
    { id: 3, color: "#FF8667", text: "Creators" },
    { id: 4, color: "#FCA2CC", text: "Marketing" },
    { id: 5, color: "#FFFFFF", text: "All" },
  ];
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1" clip-path="url(#clip0_267_349)">
        <g id="Spark Dark Aqua S">
          <g id="Clip path group">
            <mask
              id="mask0_267_349"
            //   style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <g id="clippath">
                <path id="Vector" d="M24 0H0V25H24V0Z" fill="white" />
              </g>
            </mask>
            <g mask="url(#mask0_267_349)">
              <g id="Group">
                <g id="Clip path group_2">
                  <mask
                    id="mask1_267_349"
                    // style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="25"
                  >
                    <g id="clippath-1">
                      <path id="Vector_2" d="M24 0H0V25H24V0Z" fill="white" />
                    </g>
                  </mask>
                  <g mask="url(#mask1_267_349)">
                    <g id="Group_2">
                      <path
                        id="Vector_3"
                        d="M12.2075 24.8238C13.2404 18.3481 17.8853 13.1892 23.8318 11.8796C24.0545 11.8313 24.0558 11.5368 23.8357 11.4801C18.6827 10.1403 13.6257 6.14137 12.3201 0.253476C12.2446 -0.0844921 11.748 -0.0844921 11.6725 0.253476C10.367 6.13775 5.31507 10.1354 0.164623 11.4776C-0.0580849 11.5356 -0.055525 11.8325 0.168463 11.882C6.11375 13.194 10.701 18.3517 11.7685 24.825C11.8069 25.0591 12.1704 25.0591 12.2075 24.825"
                        fill={props.team ? colors[props.team].color : "#FFFFFF"}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_267_349">
          <rect width="24" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MiniSpark;
